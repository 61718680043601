import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import TextField from "../../../../reusable-form-fields/text-field";
import ColorPickerWithTextField from "../../../../reusable-form-fields/color-picker-with-text-field";
import { useTranslation } from "react-i18next";

const HeadlineTextAndColorRow = () => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const headlineColorPickerRef = useRef("");
  return (
    <Row>
      <Col xs={12} md={6} className="mb-4">
        <TextField
          type="text"
          register={register}
          name="data.headlineText"
          label={t("preLandingPageOne.headlineTextLabel")}
          placeholder={`${t("common.enter")} ${t(
            "preLandingPageOne.headlineTextLabel"
          )}`}
          errors={errors?.data?.headlineText?.message}
        />
      </Col>
      <Col xs={12} md={6} className="mb-4">
        <ColorPickerWithTextField
          label={t("preLandingPageOne.headlineColorLabel")}
          fieldName="data.headlineColor"
          fieldError={errors?.data?.headlineColor}
          fieldRef={headlineColorPickerRef}
          fieldVal={watch("data.headlineColor")}
          isInGroup
          noSpacing
          hideErrMsg
        />
      </Col>
    </Row>
  );
};
export default HeadlineTextAndColorRow;
