import { saveAs } from "file-saver";
import styled from "styled-components";
import { Form, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FUNNEL_TYPE_FILTER } from "../strings";
import { Group } from "../common-styling";
import { useTranslation } from "react-i18next";
// import CreateNewModal from "../create-new-modal/create-new-modal";
import {
  transformOption,
  filterTypeOptions
} from "../../services/common-service";
import SplitFilterButton from "./split-filter-button";
import { downloadLeadsAsCsv } from "../../services/api-declaration";
import { showError, showSuccess } from "../../services/toast-service";

const CustomBadge = styled.div`
  top: -8px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  background: var(--lead-color);
`;

const Filter = ({
  pageType = "",
  pageName,
  pageNameList = [],
  btnText,
  placeholder = "",
  shouldDisplayResourceType = true,
  hideAddNew = false,
  handleModal,
  downloadLeadsButton = false,
  isDownloadLeadsButtonDisabled = false
}) => {
  const searchRef = useRef();
  const { t } = useTranslation();
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [activePageName, setActivePageName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleQueryParams = (property, value) => {
    if (!value) {
      searchParams.delete(property);
      setSearchParams(searchParams);
      return;
    }
    if (
      (property === activePageName || property === pageType) &&
      searchParams.get("pageIndex")
    ) {
      searchParams.set("pageIndex", "1");
    }
    searchParams.set(property, value);
    setSearchParams(searchParams);
  };

  const handlePageSelect = (value) => {
    searchParams.delete(activePageName);
    setActivePageName(value);
  };

  const handleTypeSelect = (value) => {
    if (value === FUNNEL_TYPE_FILTER.ALL) {
      handleFilterChange("");
    } else {
      handleFilterChange(value);
    }
  };

  const handleFilterChange = (value) => {
    handleQueryParams(pageType, value);
  };

  useEffect(() => {
    if (searchParams.get(activePageName) || searchParams.get(pageType)) {
      setIsFilterActive(true);
    } else {
      setIsFilterActive(false);
    }
  }, [searchParams, activePageName, pageType]);

  useEffect(() => {
    if (!activePageName) {
      setActivePageName(
        pageNameList?.length ? pageNameList[0].value : pageName
      );
    }
  }, [pageName, pageNameList, activePageName]);

  const handleDownloadLeadsAsCsv = async () => {
    try {
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      delete params.pageIndex;
      const streamResponse = await downloadLeadsAsCsv({ ...params });
      saveAs(streamResponse.data, `${searchParams.get("funnel")}-leads.csv`); // filename: funnelID-leads.csv
      showSuccess(t("toastMessage.downloadSuccessfully"));
    } catch (error) {
      showError(t("toastMessage.csvDownloadError"));
      console.log("Error in handleDownloadLeadsAsCsv ", error);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end">
        {downloadLeadsButton && (
          <div className="me-2">
            <Button
              onClick={handleDownloadLeadsAsCsv}
              disabled={isDownloadLeadsButtonDisabled}
            >
              <FontAwesomeIcon className="me-2" icon={faFileArrowDown} />
              {t("downloadAsCsv")}
            </Button>
          </div>
        )}
        <Group className={`${!hideAddNew && "me-3"}`}>
          {shouldDisplayResourceType ? (
            <SplitFilterButton
              title={
                transformOption(searchParams.get(pageType) || "") ||
                transformOption(FUNNEL_TYPE_FILTER.ALL)
              }
              options={filterTypeOptions.map((d) => ({
                eventKey: d,
                funcKey: d.toUpperCase(),
                label: d
              }))}
              handleChange={handleTypeSelect}
            />
          ) : pageNameList?.length ? (
            <SplitFilterButton
              title={
                pageNameList.find((d) => d.value === activePageName)?.label ||
                pageNameList[0].label
              }
              options={pageNameList.map((d) => ({
                eventKey: d.value,
                funcKey: d.value,
                label: d.label
              }))}
              handleChange={handlePageSelect}
            />
          ) : (
            <></>
          )}
          <Form.Control
            type="text"
            placeholder={placeholder}
            ref={searchRef}
            defaultValue={searchParams.get(activePageName) || ""}
            onKeyDown={(ev) => {
              if (ev.key === "Enter") {
                handleQueryParams(activePageName, searchRef.current.value);
              }
            }}
          />
          <Button
            id="button-search"
            variant="outline-secondary"
            onClick={() =>
              handleQueryParams(activePageName, searchRef.current.value)
            }
          >
            {t("common.search")}
            {isFilterActive && <CustomBadge />}
          </Button>
        </Group>
        {!hideAddNew && (
          <Button variant="primary" onClick={handleModal}>
            <FontAwesomeIcon icon={faPlus} />
            <span className="ms-2">{btnText}</span>
          </Button>
        )}
      </div>
    </>
  );
};
export default Filter;
