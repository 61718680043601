import { useState } from "react";
import { Button } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useDialog } from "../../hooks/useDialog";
import EditorModal from "../reusable-form-fields/editor-modal";
import { useTranslation } from "react-i18next";

const ButtonContainer = styled.div`
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
`;

const EditorModalController = ({
  title,
  context,
  includeExtraTitle = true
}) => {
  const { t } = useTranslation();
  const [showQuill, setShowQuill] = useState(false);
  const { showConfirmationDialogBox } = useDialog();

  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogType: "danger",
      dialogMessage: renderDeleteDialogMessage(),
      responseAction: (action) =>
        action && setValue(context, "", { shouldDirty: true })
    });
  const renderDeleteDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t("editorModal.deleteConfirmationMsg", {
          what: title
        })
      }}
    />
  );
  const { getValues, setValue } = useFormContext();
  const quillConent = getValues(context);
  const hideModal = () => {
    setShowQuill(false);
  };
  const handleSave = (content) => {
    setValue(context, content, { shouldDirty: true });
    setShowQuill(false);
  };
  return (
    <>
      {!quillConent ? (
        <ButtonContainer className="d-flex">
          <Button onClick={() => setShowQuill(true)}>
            {t("common.createCustom")} {title}
          </Button>
        </ButtonContainer>
      ) : (
        <>
          <ButtonContainer className="d-flex">
            <Button
              type="button"
              className="btn btn-danger"
              onClick={toggleDialog}
            >
              {t("delete")} {title}
            </Button>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={() => setShowQuill(true)}
            >
              {t("common.edit")} {title}
            </Button>
          </ButtonContainer>
        </>
      )}

      {showQuill && (
        <EditorModal
          className="url-content"
          quillValue={getValues(context)}
          title={includeExtraTitle ? `${title} Content` : title}
          onCancel={hideModal}
          onSave={handleSave}
        />
      )}
    </>
  );
};

export default EditorModalController;
