import { Row, Col } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteButton from "../../delete-button";
import TextField from "../../reusable-form-fields/text-field";
import { getItemStyle } from "../funnel-custom-accordion/drag-drop-helper";
import { ArrayFieldsCard, CustomDroppableDiv } from "../../common-styling";

const HeroPoints = ({ move, remove, fields }) => {
  const {
    setValue,
    register,
    getValues,
    formState: { errors }
  } = useFormContext();

  const handleDragEnd = (dropResult) => {
    if (dropResult.destination) {
      if (dropResult.source.index !== dropResult.destination.index) {
        move(dropResult.source.index, dropResult.destination.index);
        getValues("data.heroContentPoints").forEach((_, idx) =>
          setValue(`data.heroContentPoints[${idx}].position`, idx)
        );
      }
    }
  };

  return (
    <div className="mt-3">
      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId="droppable-hero-point">
          {(provided, snapshot) => (
            <CustomDroppableDiv
              className="p-3 pb-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((item, index) => (
                <div key={item.id}>
                  <Draggable
                    key={String(index)}
                    draggableId={String(index)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="d-flex align-items-center"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ArrayFieldsCard key={index} className="flex-fill">
                          <Row>
                            <Col xs={12} md={6}>
                              <TextField
                                label={`Label:`}
                                placeholder={"Enter Point Label"}
                                type="text"
                                name={`data.heroContentPoints[${index}].label`}
                                register={register}
                                errors={
                                  errors?.data?.heroContentPoints
                                    ? errors.data.heroContentPoints[index]
                                        ?.label
                                    : null
                                }
                                noSpacing
                                boldLabel
                                hideErrMsg
                              />
                            </Col>
                            <Col xs={12} md={6}>
                              <TextField
                                label={`Text:`}
                                placeholder={"Enter Point Text"}
                                type="text"
                                name={`data.heroContentPoints[${index}].text`}
                                register={register}
                                errors={
                                  errors?.data?.heroContentPoints
                                    ? errors.data.heroContentPoints[index]?.text
                                    : null
                                }
                                noSpacing
                                boldLabel
                                hideErrMsg
                              />
                            </Col>
                          </Row>
                        </ArrayFieldsCard>
                        <div className="ms-2">
                          <DeleteButton
                            variant="rounded"
                            deleteId={index}
                            roundedBtnSize="md"
                            onDelete={() => remove(index)}
                            message={{
                              success: "preLandingPage.deletePointSuccess",
                              confirmation: "preLandingPage.deletePointMessage"
                            }}
                            softDelete
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </CustomDroppableDiv>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default HeroPoints;
