import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

const OutcomeOptions = ({ questionIndex }) => {
  const { watch, control } = useFormContext();
  const { t } = useTranslation();
  const outcomes = useWatch({
    name: "outcomes",
    control
  });
  return (
    <optgroup label="Outcomes">
      {outcomes?.map((outcome, index) => (
        <option
          key={outcome.id}
          value={outcome.id}
          disabled={
            watch(`funnelQA[${questionIndex}]`).shouldDisableOutcomeOption
          }
        >
          {t("jumptToOutcome", {
            num: index + 1
          })}
        </option>
      ))}
    </optgroup>
  );
};

export default OutcomeOptions;
