export const DATE_RANGE_MODE = {
  ALL_TIME: "all",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year",
  RANGE: "range"
};

export const DATE_RANGE_VALUE = {
  [DATE_RANGE_MODE.DAY]: 1,
  [DATE_RANGE_MODE.WEEK]: 1,
  [DATE_RANGE_MODE.DAY]: 1
};

export const RADIO_RANGE = {
  1: DATE_RANGE_MODE.ALL_TIME,
  2: DATE_RANGE_MODE.DAY,
  3: DATE_RANGE_MODE.WEEK,
  4: DATE_RANGE_MODE.MONTH,
  5: DATE_RANGE_MODE.YEAR,
  6: DATE_RANGE_MODE.RANGE
};

export const RADIOS = [
  { name: "All Time", value: "1" },
  { name: "Day", value: "2" },
  { name: "Week", value: "3" },
  { name: "Month", value: "4" },
  { name: "Year", value: "5" },
  { name: "Range", value: "6" }
];

export const datePickerFormat = {
  [DATE_RANGE_MODE.RANGE]: "yyyy-MM-dd",
  [DATE_RANGE_MODE.DAY]: "yyyy-MM-dd",
  [DATE_RANGE_MODE.WEEK]: "yyyy-MM-dd",
  [DATE_RANGE_MODE.MONTH]: "MM/yyyy",
  [DATE_RANGE_MODE.YEAR]: "yyyy"
};

export const INSIGHT_TYPE = {
  LANDING_PAGE: "landingPage",
  QUESTION: "question",
  DIRECTLY_OUTCOME: "directly",
  RESTART_OUTCOME: "restart",
  END_OUTCOME: "end",
  ZIP: "zip",
  LEAD: "lead",
  LEAD_STEP_ONE: "leadStepOne",
  LEAD_CREATED: "leadCreated"
  // ANSWER: "answer"
};

export const amountFormatter = (num) => {
  let formats = ["B", "M", "K"];
  let r = { num, format: "" };
  formats.some((format, i, formats) => {
    let calculated = num / Math.pow(1000, formats.length - i);
    if (calculated >= 1) {
      r = { num: calculated, format };
      return true;
    }
    return false;
  });
  r.num = Math.round(r.num * 10) / 10;
  return `${r.num}${r.format}`;
};

export const generateCustomDateRange = (dateRangeMode, date) => {
  let startDate = new Date(date.toDateString());
  let endDate = new Date(date.toDateString());
  const now = new Date();
  switch (dateRangeMode) {
    case DATE_RANGE_MODE.DAY:
      // endDate.setDate(startDate.getDate()+1);
      break;
    case DATE_RANGE_MODE.WEEK:
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      if (date.getTime() !== now.getTime()) {
        endDate.setDate(startDate.getDate() + 6);
      }
      break;
    case DATE_RANGE_MODE.MONTH:
      startDate.setDate(1);
      endDate.setMonth(startDate.getMonth() + 1);
      endDate.setDate(startDate.getDate() - 1);
      break;
    case DATE_RANGE_MODE.YEAR:
      startDate.setDate(1);
      startDate.setMonth(0);
      endDate.setFullYear(startDate.getFullYear() + 1);
      endDate.setMonth(0);
      endDate.setDate(startDate.getDate() - 1);
      break;
    default:
      break;
  }
  return [startDate, endDate];
};

export const generateDateRange = (dateRangeMode) => {
  let start = new Date(new Date().toDateString());

  switch (dateRangeMode) {
    case DATE_RANGE_MODE.YEAR:
      start.setFullYear(start.getFullYear() - 1);
      break;

    case DATE_RANGE_MODE.ALL_TIME:
    case DATE_RANGE_MODE.MONTH:
      start.setMonth(start.getMonth() - 1);
      break;

    case DATE_RANGE_MODE.WEEK:
      start.setDate(start.getDate() - 7);
      break;

    case DATE_RANGE_MODE.DAY:
    default:
  }

  return start;
};

export const transformData = ({ graph, dateRangeMode }) => {
  const convertDate = ({ _id }) => {
    const split = _id.split("--");
    if (split[1]) {
      split[1] -= 1;
    }
    return new Date(...split);
  };
  const createDateId = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    let _id = [year, month];

    switch (dateRangeMode) {
      case DATE_RANGE_MODE.DAY:
        _id.push(day, hour);
        break;
      case DATE_RANGE_MODE.WEEK:
      case DATE_RANGE_MODE.MONTH:
        _id.push(day);
        break;
      default:
    }

    return _id.join("--");
  };
  const dateIncrementor = (date) => {
    switch (dateRangeMode) {
      case DATE_RANGE_MODE.DAY:
        date.setHours(date.getHours() + 1);
        break;
      case DATE_RANGE_MODE.WEEK:
        date.setDate(date.getDate() + 1);
        break;
      case DATE_RANGE_MODE.MONTH:
        date.setDate(date.getDate() + 1);
        break;
      case DATE_RANGE_MODE.ALL_TIME:
      case DATE_RANGE_MODE.YEAR:
      default:
        date.setMonth(date.getMonth() + 1);
    }
    return date;
  };
  const dataMap = new Map(
    graph.map((d) => {
      d.date = convertDate(d);
      return [d._id, d];
    })
  );
  let start = generateDateRange(dateRangeMode);
  if (start > graph[0]?.date) {
    start = new Date(graph[0].date);
  }

  function mapData(date) {
    const _id = createDateId(date);
    let d = dataMap.get(_id);
    if (!d) {
      d = { _id, date, count: 0 };
    }
    return d;
  }

  return (function createTimelineData(data, date, end, mapData) {
    data.push(mapData(date));
    date = dateIncrementor(new Date(date));
    if (date <= end) {
      return createTimelineData(data, date, end, mapData);
    }
    return data;
  })([], start, new Date(), mapData);
};
