import styled from "styled-components";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { useEffect, useRef, useState } from "react";
import AccordionBody from "react-bootstrap/AccordionBody";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  QUESTION_TYPE,
  FUNNEL_QA_ACTIONS,
  QUESTION_POSITION
} from "../../strings";
import QuestionList from "./question";
import AnswerList from "./answer-list";
import { useDialog } from "../../../hooks/useDialog";
import { getListStyle, getItemStyle } from "./drag-drop-helper";
import { getFunnelQuestion } from "../../../services/api-declaration";
import { removeDuplicatedIds } from "../../../services/common-service";
import { showError, showSuccess } from "../../../services/toast-service";
import { AccordionItemContainer, BootstrapCard } from "../../common-styling";
import { useTranslation } from "react-i18next";

const AnswersBody = styled(AccordionBody)`
  background: var(--body-light-bg);
`;

const FunnelQA = ({
  resourceId,
  parentFolderName,
  errors,
  selectedCategory
}) => {
  const { t } = useTranslation();
  const [questionLoading, setQuestionLoading] = useState(false);
  const { control, getValues, setValue, clearErrors } = useFormContext();
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const [activeKey, setActiveKey] = useState(0);
  const deleteQuestionAnswerPositions = useRef({});
  const changeLastQuestion = () => {
    const allQuestions = getValues("funnelQA");
    if (allQuestions.length) {
      allQuestions[allQuestions.length - 1].answers.forEach(
        (answer) => (answer.answerAction = QUESTION_POSITION.last)
      );
    }
  };
  const { fields, append, move, remove, update } = useFieldArray({
    control,
    name: "funnelQA" // unique name for your Field Array
  });
  const renderDialogMessage = () => (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t("modalContent.deleteFunnel")
        }}
      ></div>
      <div>
        {t("modalContent.responseAction")}{" "}
        <span className="text-danger fw-bold">
          {t("question", { count: 1 })} #{" "}
          {Object.keys(deleteQuestionAnswerPositions.current).map(
            (e, i) =>
              +e +
              1 +
              `${
                Object.keys(deleteQuestionAnswerPositions.current).length !==
                i + 1
                  ? ","
                  : ""
              }`
          )}
        </span>
      </div>
    </>
  );
  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogType: "danger",
      dialogMessage: renderDialogMessage(),
      responseAction: dialogResponseAction
    });
  const dialogResponseAction = (action, values) => {
    if (action) {
      remove(values.currentQuesIndex);
      getValues("funnelQA").forEach((qa, index) => {
        qa.position = index;
      });
      Object.keys(deleteQuestionAnswerPositions.current).forEach((qaIndex) => {
        deleteQuestionAnswerPositions.current[qaIndex].forEach((ansIndex) => {
          getValues("funnelQA")[Number(qaIndex)].answers[
            ansIndex
          ].answerAction = QUESTION_POSITION.next;
        });
      });
      changeLastQuestion();
      showSuccess(`Frage # ${values.currentQuesIndex + 1} wurde gelöscht.`);
    } else {
      deleteQuestionAnswerPositions.current = {};
    }
  };

  const addNewFunnelQuestion = () => {
    append({
      id: `${fields.length + 1}`,
      text: "",
      subtext: "",
      position: fields.length,
      type: QUESTION_TYPE.ICONS, // For Testing only
      answers: [],
      isMultiSelectable: false
    });
    clearErrors("funnelQA");
  };
  const onAction = async (questionIndex, action) => {
    if (action === FUNNEL_QA_ACTIONS.COPY) {
      const { _id } = getValues("funnelQA")[questionIndex];
      navigator.clipboard
        .writeText(JSON.stringify({ funnelId: resourceId, questionId: _id }))
        .then(() => showSuccess(t("clipboardCopySuccess")));
    }
    if (action === FUNNEL_QA_ACTIONS.DELETE) {
      const questionPosition = fields[questionIndex].position;
      let flag = false;
      deleteQuestionAnswerPositions.current = {};
      getValues("funnelQA").forEach((qa, qaIndex) => {
        deleteQuestionAnswerPositions.current[qaIndex] = [];
        qa.answers.forEach((answer, ansIndex) => {
          if (
            answer.answerAction &&
            Number(answer.answerAction) === questionPosition
          ) {
            flag = true;
            deleteQuestionAnswerPositions.current?.[qaIndex].push(ansIndex);
          }
        });
        if (!deleteQuestionAnswerPositions.current[qaIndex].length) {
          delete deleteQuestionAnswerPositions.current[qaIndex];
        }
      });
      if (flag) {
        setDependentStates({ currentQuesIndex: questionIndex });
        toggleDialog();
        return;
      }
    }

    if (action === FUNNEL_QA_ACTIONS.DUPLICATE) {
      const question = JSON.parse(
        JSON.stringify(getValues("funnelQA")[questionIndex])
      );
      removeDuplicatedIds(question);
      append({
        ...question,
        id: `${getValues("funnelQA").length + 1}`,
        position: Number(`${getValues("funnelQA").length}`)
      });
    } else if (action === FUNNEL_QA_ACTIONS.DELETE) {
      remove(questionIndex);
      getValues("funnelQA").forEach((qa, index) => {
        qa.position = index;
      });
      changeLastQuestion();
    }
  };
  const handleOnDragEnd = (dropResult) => {
    if (dropResult.destination) {
      if (dropResult.source.index !== dropResult.destination.index) {
        const questions = getValues("funnelQA");
        const sourceIndex = dropResult.source.index;
        const destinationIndex = dropResult.destination.index;
        // check if question is not multi selectable
        if (!questions[sourceIndex].isMultiSelectable) {
          // update source answerAction
          questions[sourceIndex].answers.forEach((_, ind) => {
            setValue(
              `funnelQA[${sourceIndex}].answers[${ind}].answerAction`,
              ""
            );
          });
          // update destination answerAction
          questions[destinationIndex].answers.forEach((e, ind) => {
            setValue(
              `funnelQA[${destinationIndex}].answers[${ind}].answerAction`,
              ""
            );
          });
        }
        move(sourceIndex, destinationIndex);
        questions.forEach((_, ind) =>
          setValue(`funnelQA[${ind}].position`, ind)
        );
      }
    }
  };
  useEffect(() => {
    setActiveKey(fields.length - 1);
  }, [fields.length]);
  const pasteQuestion = async () => {
    try {
      setQuestionLoading(true);
      let clipboardText;
      try {
        clipboardText = await navigator.clipboard.readText();
      } catch (err) {
        console.log(err);
        showError(t("clipBoardNotAllowedMessage"));
        return;
      }
      const { funnelId, questionId } = JSON.parse(clipboardText);
      const { data } = await getFunnelQuestion(funnelId, questionId);
      if (data.data.funnelQA.length > 0) {
        const questionData = data.data.funnelQA[0];
        removeDuplicatedIds(questionData);
        append({
          isDuplicated: true,
          ...questionData,
          id: `${fields.length + 1}`,
          position: fields.length
        });
        showSuccess(t("pasteQuestionSuccess"));
      }
    } catch (error) {
      showError(t("pasteQuestionError"));
    } finally {
      setQuestionLoading(false);
    }
  };
  return (
    <div>
      <div className="d-flex justify-content-end gap-1">
        <Button variant="primary" onClick={addNewFunnelQuestion}>
          {t("addNewQuestion")}
        </Button>
        <OverlayTrigger overlay={<Tooltip>{t("pasteQuestion")}</Tooltip>}>
          <Button
            disabled={questionLoading}
            onClick={pasteQuestion}
            variant="outline-primary"
          >
            {questionLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <FontAwesomeIcon icon={faPaste} />
            )}
          </Button>
        </OverlayTrigger>
      </div>
      <Accordion activeKey={activeKey}>
        <DragDropContext onDragEnd={(res) => handleOnDragEnd(res)}>
          <Droppable droppableId="droppable-question">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {fields.map((item, index) => (
                  <AccordionItemContainer eventKey={index} key={item.id}>
                    <Draggable
                      key={String(index)}
                      draggableId={String(index)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <BootstrapCard
                            className="mt-4"
                            key={index}
                            $showShadow={true}
                          >
                            <QuestionList
                              index={index}
                              activeKey={activeKey}
                              onAction={onAction}
                              setActiveKey={setActiveKey}
                              errors={errors}
                            />
                            <AnswersBody>
                              <Card.Body className="px-0 pt-0">
                                <AnswerList
                                  parentFolderName={parentFolderName}
                                  update={update}
                                  questionIndex={index}
                                  resourceId={resourceId}
                                  errors={errors}
                                  selectedCategory={selectedCategory}
                                />
                              </Card.Body>
                            </AnswersBody>
                          </BootstrapCard>
                        </div>
                      )}
                    </Draggable>
                  </AccordionItemContainer>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Accordion>
    </div>
  );
};
export default FunnelQA;
