import { useEffect } from "react";
import ReactQuill from "react-quill";
import { Row, Col, Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import {
  parseResourceSrc,
  parsePreviewResourceSrc
} from "../../../../../services/common-service";
import FileInput from "../../../../reusable-form-fields/input-file";
import { useTranslation } from "react-i18next";

const HeroImageAndContentRow = ({ transKey }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const heroImage = watch("data.heroImage");
  const heroContent = watch("data.heroContent");
  // forcefully removing error
  useEffect(() => {
    if (heroImage && Object.keys(heroImage)?.length) {
      clearErrors("data.heroImage");
    }
  }, [heroImage, clearErrors]);
  useEffect(() => {
    if (heroContent) {
      clearErrors("data.heroContent");
    }
  }, [heroContent, clearErrors]);
  return (
    <Row>
      <Col xs={12} md={6}>
        <Form.Group className="mb-4" controlId="heroContent">
          <Form.Label
            className={`mb-1 ${errors?.data?.heroContent ? "text-danger" : ""}`}
          >
            {t(`${transKey}.heroContentLabel`)}
          </Form.Label>
          <ReactQuill
            theme="snow"
            value={heroContent}
            onChange={(value) => {
              setValue("data.heroContent", value, { shouldDirty: true });
            }}
            preserveWhitespace
          />
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <FileInput
          label={t(`${transKey}.heroImageLabel`)}
          name="data.heroImage"
          parentFolderName={"funnel-landing-page"}
          url={parseResourceSrc(heroImage)}
          urlForPreview={parsePreviewResourceSrc(heroImage)}
          asset={heroImage?.asset}
          error={errors?.data?.heroImage}
        />
      </Col>
    </Row>
  );
};
export default HeroImageAndContentRow;
