import { useFormContext } from "react-hook-form";
import RadioInput from "./input-radio";
import { FOOTER_FOREGROUND } from "../strings";
import { useTranslation } from "react-i18next";

const FooterTextColorRadio = ({ name, labelSpacing = "", error = null }) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const { BLACK, WHITE, SHADOWED } = FOOTER_FOREGROUND;
  return (
    <RadioInput
      name={name}
      errors={error}
      register={register}
      label={t("footerTxtColor")}
      labelSpacing={labelSpacing}
      firstOptLabel={t("footerColors.black")}
      secondOptLabel={t("footerColors.white")}
      thirdOptLabel={t("footerColors.whiteShadow")}
      firstValue={BLACK}
      firstValueId={BLACK}
      secondValue={WHITE}
      secondValueId={WHITE}
      thirdValue={SHADOWED}
      thirdValueId={SHADOWED}
    />
  );
};
export default FooterTextColorRadio;
