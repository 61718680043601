import { useEffect, useRef } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import HeroPoints from "../hero-points";
import {
  parseResourceSrc,
  parsePreviewResourceSrc
} from "../../../../services/common-service";
import FileInput from "../../../reusable-form-fields/input-file";
import TextField from "../../../reusable-form-fields/text-field";
import BenefitsGridRow from "./reusable-template-rows/benefits-grid-row";
import FooterTextColorRadio from "../../../reusable-form-fields/footer-text-color-radio";
import HeroImageAndContentRow from "./reusable-template-rows/hero-image-and-content-row";
import HeadlineTextAndColorRow from "./reusable-template-rows/headline-text-and-color-row";
import ColorPickerWithTextField from "../../../reusable-form-fields/color-picker-with-text-field";
import BenefitsGridLabelAndButtonRow from "./reusable-template-rows/benefits-grid-label-and-button-row";
import CallToActionButtonTextColorBackgroundRow from "./reusable-template-rows/cta-button-text-color-background-row";
import BenefitsGridHeadlineTextAndColorRow from "./reusable-template-rows/benefits-grid-headline-text-and-color-row";
import { useTranslation } from "react-i18next";

const LandingPageTempTwo = () => {
  const { t } = useTranslation();
  const {
    watch,
    control,
    register,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const {
    fields: benefitGridFields,
    append: benefitGridAppend,
    move: benefitGridMove,
    remove: benefitGridRemove
  } = useFieldArray({
    control,
    name: "data.benefitsGrid",
    rules: { maxLength: 10 }
  });
  const {
    fields: heroPointFields,
    append: heroPointAppend,
    move: heroPointMove,
    remove: heroPointRemove
  } = useFieldArray({
    control,
    name: "data.heroContentPoints",
    rules: { maxLength: 3 }
  });
  const backgroundImage = watch("data.backgroundImage");
  const subHeadlineColorPickerRef = useRef("");
  const phoneNumberColorPickerRef = useRef("");
  const contactUsButtonColorPickerRef = useRef("");
  const centerContentBackgroundColorPickerRef = useRef("");
  const contactUsButtonHoverBackgroundPickerRef = useRef("");
  const heroContentTextColorPickerRef = useRef("");
  const heroContentIconColorPickerRef = useRef("");

  const addNewBenefitGrid = () => {
    benefitGridAppend({
      id: `${benefitGridFields.length + 1}`,
      position: benefitGridFields.length,
      label: "",
      icon: ""
    });
    if (errors?.data?.benefitsGrid) {
      clearErrors("data.benefitsGrid");
    }
  };
  const addNewHeroContentPoint = () => {
    heroPointAppend({
      id: `${heroPointFields.length + 1}`,
      position: heroPointFields.length,
      label: "",
      text: ""
    });
    if (errors?.data?.heroContentPoints) {
      clearErrors("data.heroContentPoints");
    }
  };

  // forcefully removing error
  useEffect(() => {
    if (backgroundImage && Object.keys(backgroundImage)?.length) {
      clearErrors("data.backgroundImage");
    }
  }, [backgroundImage, clearErrors]);

  return (
    <div>
      <Row className="mt-4">
        <Col xs={12} md={6}>
          <FileInput
            label={t("preLandingPageTwo.backgroundImageLabel")}
            name="data.backgroundImage"
            parentFolderName={"funnel-landing-page"}
            url={parseResourceSrc(backgroundImage)}
            urlForPreview={parsePreviewResourceSrc(backgroundImage)}
            asset={backgroundImage?.asset}
            selectedCategory={""}
            error={errors?.data?.backgroundImage}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.centerContentBackgroundColorLabel")}
            fieldName="data.centerContentBackgroundColor"
            fieldError={errors?.data?.centerContentBackgroundColor}
            fieldRef={centerContentBackgroundColorPickerRef}
            fieldVal={watch("data.centerContentBackgroundColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <Form.Label>{`${t(
            "preLandingPageTwo.centerContentBackgroundOpacityLabel"
          )}: ${
            watch("data.centerContentBackgroundOpacity") || 0.8
          }`}</Form.Label>
          <Form.Range
            className="mt-2"
            min={0}
            max={1}
            step={0.1}
            defaultValue={0.8}
            {...register("data.centerContentBackgroundOpacity")}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.contactUsButtonText"
            label={t("preLandingPageTwo.contactUsButtonTextLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageTwo.contactUsButtonTextLabel"
            )}`}
            errors={errors?.data?.contactUsButtonText?.message}
          />
        </Col>
        <Col xs={12} md={3} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.contactUsButtonColorLabel")}
            fieldName="data.contactUsButtonColor"
            fieldError={errors?.data?.contactUsButtonColor}
            fieldRef={contactUsButtonColorPickerRef}
            fieldVal={watch("data.contactUsButtonColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
        <Col xs={12} md={3} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.contactUsButtonBackgroundHoverLabel")}
            fieldName="data.contactUsButtonHoverBackground"
            fieldError={errors?.data?.contactUsButtonHoverBackground}
            fieldRef={contactUsButtonHoverBackgroundPickerRef}
            fieldVal={watch("data.contactUsButtonHoverBackground")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.phoneNumberHeadline"
            label={t("preLandingPageTwo.phoneNumberHeadlineLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageTwo.phoneNumberHeadlineLabel"
            )}`}
            errors={errors?.data?.phoneNumberHeadline?.message}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.phoneNumberText"
            label={t("preLandingPageTwo.phoneNumberTextLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageTwo.phoneNumberTextLabel"
            )}`}
            errors={errors?.data?.phoneNumberText?.message}
          />
        </Col>
        <Col xs={12} md={4} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.phoneNumberColorLabel")}
            fieldName="data.phoneNumberColor"
            fieldError={errors?.data?.phoneNumberColor}
            fieldRef={phoneNumberColorPickerRef}
            fieldVal={watch("data.phoneNumberColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <HeadlineTextAndColorRow />
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.subHeadlineText"
            label={t("preLandingPageTwo.subHeadlineTextLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageTwo.subHeadlineTextLabel"
            )}`}
            errors={errors?.data?.subHeadlineText?.message}
          />
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.subHeadlineColorLabel")}
            fieldName="data.subHeadlineColor"
            fieldError={errors?.data?.subHeadlineColor}
            fieldRef={subHeadlineColorPickerRef}
            fieldVal={watch("data.subHeadlineColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <HeroImageAndContentRow transKey="preLandingPageTwo" />
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.heroContentTextColorLabel")}
            fieldName="data.heroContentTextColor"
            fieldError={errors?.data?.heroContentTextColor}
            fieldRef={heroContentTextColorPickerRef}
            fieldVal={watch("data.heroContentTextColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageTwo.heroContentIconColorLabel")}
            fieldName="data.heroContentIconColor"
            fieldError={errors?.data?.heroContentIconColor}
            fieldRef={heroContentIconColorPickerRef}
            fieldVal={watch("data.heroContentIconColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col xs={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className={`fw-bold ${
                errors?.data?.heroContentPoints ? "text-danger" : ""
              }`}
            >
              {`${t("preLandingPageTwo.heroPoints.headingLabel")}
              ${errors?.data?.heroContentPoints ? "*" : ""}`}
            </div>
            <div className="ms-3">
              <Button
                className="w-100"
                onClick={addNewHeroContentPoint}
                disabled={heroPointFields.length === 3}
              >
                {t("preLandingPageTwo.heroPoints.btnLabel")}
                {heroPointFields.length ? (
                  <Badge bg="secondary" className="rounded-circle ms-2">
                    {heroPointFields.length}
                  </Badge>
                ) : (
                  ""
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {heroPointFields.length ? (
        <Row className="mb-4">
          <Col xs={12}>
            <HeroPoints
              move={heroPointMove}
              remove={heroPointRemove}
              fields={heroPointFields}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <CallToActionButtonTextColorBackgroundRow />
      <BenefitsGridHeadlineTextAndColorRow />
      <BenefitsGridLabelAndButtonRow
        gridLimit={10}
        fields={benefitGridFields}
        addNew={addNewBenefitGrid}
      />
      <BenefitsGridRow
        move={benefitGridMove}
        remove={benefitGridRemove}
        fields={benefitGridFields}
      />
      <Row className="mt-4">
        <Col xs={12}>
          <FooterTextColorRadio
            name="data.footerForeground"
            error={errors?.data?.footerForeground}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12}>
          <TextField
            type="text"
            register={register}
            name="data.footerGoogleMapIframeUrl"
            label={t("preLandingPageTwo.footerMapLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageTwo.footerMapLabel"
            )}`}
            errors={errors?.data?.footerGoogleMapIframeUrl?.message}
          />
        </Col>
      </Row>
    </div>
  );
};
export default LandingPageTempTwo;
