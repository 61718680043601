import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import OutcomeOptions from "./outcome-options";
import { QUESTION_POSITION } from "../../strings";
import { BorderlessSelect } from "../../common-styling";
import { useTranslation } from "react-i18next";

const AnswerActionsDropdown = ({ field, isMulti, questionIndex }) => {
  const { t } = useTranslation();
  const { getValues, setValue, register } = useFormContext();
  const question = getValues("funnelQA")[questionIndex];

  useEffect(() => {
    if (
      question?.isMultiSelectable &&
      getValues(field)?.answerAction !== QUESTION_POSITION.multi
    ) {
      // mark all answer actions(of outcome) for that question disable with a new key
      setValue(`funnelQA[${questionIndex}].shouldDisableOutcomeOption`, true);
    }
  }, [getValues, setValue, field, questionIndex, question]);

  return (
    <BorderlessSelect
      fs="14"
      {...register(`${field}.answerAction`, {
        onChange: ({ target: { value } }) => {
          if (isMulti) {
            setValue(
              `funnelQA[${questionIndex}].shouldDisableOutcomeOption`,
              value === QUESTION_POSITION.multi ? false : true
            );
          }
          return value;
        }
      })}
    >
      {!isMulti ? (
        <>
          <option disabled value="">
            {t("funnelQA.selectOption")}
          </option>
          <optgroup label="Questions">
            {/* Jump to the next question */}
            {getValues().funnelQA?.length - 1 !== questionIndex && (
              <option value={QUESTION_POSITION.next}>
                {t("jumpToNextQuestion")}
              </option>
            )}
            {/* Jump to the question number */}
            {getValues().funnelQA?.length - 1 !== questionIndex &&
              getValues().funnelQA?.length > 1 &&
              getValues().funnelQA.map(
                (item, itemIndex) =>
                  questionIndex !== itemIndex && (
                    <option key={itemIndex} value={itemIndex}>
                      {t("jumptToQuestion")} # {itemIndex + 1}
                    </option>
                  )
              )}
            {/* End questionnaire */}
            <option value={QUESTION_POSITION.last}>
              {t("endQuestionnaire")}
            </option>
          </optgroup>
          {/* Jump to the outcome */}
          <OutcomeOptions questionIndex={questionIndex} />
        </>
      ) : (
        <>
          <optgroup label="Multi">
            <option value={QUESTION_POSITION.multi}>
              {t("funnelQA.multi")}
            </option>
          </optgroup>
          <OutcomeOptions questionIndex={questionIndex} />
        </>
      )}
    </BorderlessSelect>
  );
};
export default AnswerActionsDropdown;
