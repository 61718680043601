import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const OverlayContainer = styled.div`
  z-index: 999;
  background: #e8e8e89c;
`;

const Overlay = ({ show, showMsg = true }) => {
  const { t } = useTranslation();
  return (
    show && (
      <OverlayContainer className="d-flex justify-content-center position-absolute top-0 bottom-0 start-0 end-0">
        <div className="d-flex align-items-center">
          <Spinner animation="border" className="me-3" />
          {showMsg && `${t("overlayMsg")}...`}
        </div>
      </OverlayContainer>
    )
  );
};
export default Overlay;
