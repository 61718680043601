import { useState } from "react";
import styled from "styled-components";
import EmojiPicker from "emoji-picker-react";
import { useFormContext } from "react-hook-form";
import { Row, Col, Button } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DeleteButton from "../../delete-button";
import { LANDING_PAGE_TEMPLATES } from "../../strings";
import TextField from "../../reusable-form-fields/text-field";
import { ArrayFieldsCard, CustomDroppableDiv } from "../../common-styling";
import { getItemStyle } from "../funnel-custom-accordion/drag-drop-helper";
import { useTranslation } from "react-i18next";

const IconContainer = styled.div`
  position: absolute;
  left: 0;
  top: 75px;
  z-index: 999;
  padding: 10px;
  overflow: scroll;
  border-radius: 6px;
  background: #f6f8f9;
  border: 1px solid #ced4da;
`;
const CloseIconContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
`;
const CloseIconContainerBtn = styled(Button)`
  padding: 4px 10px !important;
`;

const BenefitsGrid = ({ move, remove, fields }) => {
  const { t } = useTranslation();
  const { FUNNEL_LANDING_PAGE_TEMP_ONE } = LANDING_PAGE_TEMPLATES;
  const {
    watch,
    setValue,
    register,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const [iconPickerToggler, setIconPickerToggler] = useState();

  const handleDragEnd = (dropResult) => {
    if (dropResult.destination) {
      if (dropResult.source.index !== dropResult.destination.index) {
        move(dropResult.source.index, dropResult.destination.index);
        getValues("data.benefitsGrid").forEach((_, idx) =>
          setValue(`data.benefitsGrid[${idx}].position`, idx)
        );
      }
    }
  };
  const clearBenefitFieldError = (value, index, field) => {
    if (value && errors?.data?.benefitsGrid[index]?.[field]) {
      clearErrors(`data.benefitsGrid[${index}][${field}]`);
    }
  };
  const handleBenefitIconPickerClick = (benefitGridIdx) =>
    setIconPickerToggler({
      [benefitGridIdx]: iconPickerToggler
        ? !iconPickerToggler[benefitGridIdx]
        : true
    });
  const checkTemplateType = (selectedId) =>
    watch("template")._id === selectedId;
  const closeEmojiPicker = () => setIconPickerToggler({ index: false });
  const handleOnSelectEmoji = (selectedItem, benefitGridIdx) => {
    const emoji = selectedItem.emoji;
    setValue(`data.benefitsGrid[${benefitGridIdx}].icon`, emoji);
    clearBenefitFieldError(emoji, benefitGridIdx, "icon");
    closeEmojiPicker();
  };

  return (
    <div className="mt-3">
      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId="droppable-benefit">
          {(provided, snapshot) => (
            <CustomDroppableDiv
              className="p-3 pb-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((item, index) => (
                <div key={item.id}>
                  <Draggable
                    key={String(index)}
                    draggableId={String(index)}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        className="d-flex align-items-center"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ArrayFieldsCard key={index} className="flex-fill">
                          <Row>
                            <Col
                              xs={12}
                              md={
                                checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_ONE)
                                  ? 4
                                  : 10
                              }
                            >
                              <TextField
                                label={t("preLandingPageOne.benefits.label")}
                                placeholder={`${t("common.enter")} ${t(
                                  "preLandingPageOne.benefits.label"
                                )}`}
                                type="text"
                                name={`data.benefitsGrid[${index}].label`}
                                register={register}
                                errors={
                                  errors?.data?.benefitsGrid
                                    ? errors.data.benefitsGrid[index]?.label
                                    : null
                                }
                                noSpacing
                                boldLabel
                                hideErrMsg
                              />
                            </Col>
                            {checkTemplateType(
                              FUNNEL_LANDING_PAGE_TEMP_ONE
                            ) && (
                              <Col xs={12} md={6}>
                                <TextField
                                  label={t(
                                    "preLandingPageOne.benefits.subtext"
                                  )}
                                  placeholder={`${t("common.enter")} ${t(
                                    "preLandingPageOne.benefits.subtext"
                                  )}`}
                                  type="text"
                                  name={`data.benefitsGrid[${index}].subText`}
                                  register={register}
                                  errors={
                                    errors?.data?.benefitsGrid
                                      ? errors.data.benefitsGrid[index]?.subText
                                      : null
                                  }
                                  noSpacing
                                  boldLabel
                                  hideErrMsg
                                />
                              </Col>
                            )}
                            <Col xs={12} md={2}>
                              <div className="text-center d-flex align-items-end position-relative">
                                <TextField
                                  type="text"
                                  register={register}
                                  textFieldClasses="text-center"
                                  name={`data.benefitsGrid[${index}].icon`}
                                  label={t("preLandingPageOne.benefits.icon")}
                                  errors={
                                    errors?.data?.benefitsGrid
                                      ? errors.data.benefitsGrid[index]?.icon
                                      : null
                                  }
                                  disabled
                                  noSpacing
                                  boldLabel
                                  hideErrMsg
                                />
                                <Button
                                  size="sm"
                                  className="ms-2 mb-1"
                                  variant="outline-primary"
                                  onClick={() =>
                                    handleBenefitIconPickerClick(index)
                                  }
                                >
                                  {t("preLandingPageOne.benefits.selectBtn")}
                                </Button>
                                {iconPickerToggler &&
                                  iconPickerToggler[index] && (
                                    <IconContainer>
                                      <CloseIconContainer>
                                        <CloseIconContainerBtn
                                          size="sm"
                                          variant="danger"
                                          className="rounded-circle"
                                          onClick={closeEmojiPicker}
                                        >
                                          <FontAwesomeIcon icon={faXmark} />
                                        </CloseIconContainerBtn>
                                      </CloseIconContainer>
                                      <EmojiPicker
                                        width={300}
                                        height={300}
                                        autoFocusSearch={false}
                                        open={
                                          (iconPickerToggler &&
                                            iconPickerToggler[index]) ||
                                          false
                                        }
                                        previewConfig={{ showPreview: false }}
                                        onEmojiClick={(item) =>
                                          handleOnSelectEmoji(item, index)
                                        }
                                      />
                                    </IconContainer>
                                  )}
                              </div>
                            </Col>
                          </Row>
                        </ArrayFieldsCard>
                        <div className="ms-3">
                          <DeleteButton
                            variant="rounded"
                            deleteId={index}
                            roundedBtnSize="md"
                            onDelete={() => remove(index)}
                            beforeDeleteConfirmation={closeEmojiPicker}
                            message={{
                              success: "preLandingPage.deleteBenefitSuccess",
                              confirmation:
                                "preLandingPage.deleteBenefitMessage"
                            }}
                            softDelete
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                </div>
              ))}
              {provided.placeholder}
            </CustomDroppableDiv>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
export default BenefitsGrid;
