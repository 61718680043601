import {
  Card,
  Badge,
  Table,
  Button,
  Spinner,
  Dropdown,
  FormSelect,
  InputGroup,
  ModalHeader,
  FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

const FieldSet = styled.fieldset`
  padding: 15px;
  border-radius: 6px;
`;

const Legend = styled.legend`
  float: none;
  width: ${({ filled }) => (filled ? "100%" : "auto")};
  margin-bottom: 0;
`;

const BootstrapTable = styled(Table)`
  margin-bottom: 0;
  border-collapse: initial;
  border-spacing: 0;
  // border-spacing: 0 16px;
`;

const PageHeading = styled.div`
  color: #000;
  font-size: 25px;
  font-weight: 700;
  margin-top: 18px;
  margin-left: 5px;
`;

// For table component
const TableHead = styled.th`
  font-weight: 600;
  vertical-align: middle;
  color: var(--secondary-text);
  background: #f8f9fb !important;
  width: ${({ empty }) => (empty ? "160px" : "auto")};
`;
const HeaderColumn = styled(TableHead)`
  border-top: 1px solid var(--th-border-color);
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : "auto")};
`;
const FirstHeaderColumn = styled(TableHead)`
  border-left: 1px solid var(--th-border-color);
  border-bottom-left-radius: 6px;
  border-top: 1px solid var(--th-border-color);
  border-top-left-radius: 6px;
  padding: 16px 12px 16px 16px !important;
`;
const LastHeaderColumn = styled(TableHead)`
  border-right: 1px solid var(--th-border-color);
  border-bottom-right-radius: 6px;
  border-top: 1px solid var(--th-border-color);
  border-top-right-radius: 6px;
`;

const TableRow = styled.tr`
  vertical-align: middle;
  transition: 0.25s all ease-in-out;
  background: #fff;
  border-bottom: 1px solid var(--th-border-color);
  &:hover {
    background: #f8f9fb;
  }
`;

const BasicTD = styled.td`
  border: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--secondary-text);
  border-bottom: 1px solid var(--th-border-color);
`;
const TablePercentageCell = styled(BasicTD)`
  width: 150px;
  min-width: 200px;
`;
const TableDetailSwitchCell = styled(BasicTD)`
  width: 100px;
  max-width: 150px;
  // padding: 15px 8px !important;
`;
const TableDetailCell = styled(BasicTD)`
  width: 150px;
  max-width: 150px;
  // padding: 15px 8px !important;
`;
const TableDetailNameCell = styled(BasicTD)`
  font-weight: 600;
  max-width: 500px;
  // padding: 15px 8px !important;
  &:hover {
    color: var(--primary-color);
  }
`;
// -------------------

const Group = styled(InputGroup)`
  width: fit-content;
`;

const FunnelTypeBadge = styled(Badge)`
  text-transform: capitalize;
  color: ${({ btncolor }) => `var(${btncolor})`};
  background-color: ${({ btnbg }) => `var(${btnbg})`} !important;
`;

const ValiditySpinner = styled(Spinner)`
  width: 25px;
  height: 25px;
`;

const ModalHead = styled(ModalHeader)`
  background: ${({ bg }) => `var(--${bg}-color)`};
`;

const CardContainer = styled(Card)`
  border: none;
  box-shadow: -5px 5px 10px #bdbdbd;
  box-shadow: 0px 10px 10px 0px #38476d14;
`;

const PerformanceCardContainer = styled(Card)`
  border: none;
  box-shadow: 0px 1.5px 4px 0px #38476d24;
  margin: 8px 4px;
`;

const CustomCard = styled(Card)`
  border: 1px solid var(--th-border-color);
`;

const FixedContainer = styled.div`
  right: 20px;
  bottom: 20px;
  display: flex;
  color: #6d757d;
  position: fixed;
  font-weight: bold;
  align-items: center;
`;

const HorizontalDivider = styled.hr`
  width: ${({ customWidth }) => (customWidth ? `${customWidth}%` : "100%")};
  margin-top: 0;
  color: #959ba1;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #959ba1;
  height: 80%;
  position: absolute;
  right: 0px;
  top: 10%;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: var(--secondary-text);
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  outline: 0;
  border: 0;
  &::after {
    display: none;
  }
  &:hover {
    border: 0;
  }
  &:focus-visible {
    outline: 0;
    border: 0;
  }
`;

const BorderlessSelect = styled(FormSelect)`
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  font-size: ${({ fs }) => `${fs}px`};
  text-align: ${({ align }) => (align ? align : "left")};
  border: 0;
  &:focus {
    box-shadow: none;
  }
`;

const BorderlessInput = styled(FormControl)`
  border: 0;
  height: 100%;
  border-radius: 0;
  &:focus {
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
  }
`;

const FixedWidthDiv = styled.div`
  width: ${({ width }) => `${width}px`};
`;

const FCInputColor = styled(FormControl)`
  border-left: none;
  align-self: end;
  &:focus {
    box-shadow: none;
    border-color: var(--field-border-color-regular);
  }
`;

const FCInputColorButton = styled(Button)`
  align-self: end;
`;

const EllipsisDiv = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SubTextDiv = styled(EllipsisDiv)`
  color: #919191;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
`;

const FunnelNameColumn = styled(EllipsisDiv)`
  color: ${({ funnelType }) => `var(${funnelType})`};
`;

const AnswerRow = styled.div`
  background-color: #fff;
  border-radius: 0 !important;
  border: 1px solid var(--light-border);
`;

const FontSizeTwelve = styled.p`
  font-size: 12px;
`;

const fadedBackground = keyframes`
  from { background: #e3f2fd }
  to { background: #fff }
`;

const FadedBackgroundDiv = styled.div`
  background: #fff;
  animation: ${fadedBackground} 0.75s ease-in-out;
`;

const BootstrapCard = styled(Card)`
  background-color: transparent;
  border-radius: 0;
  box-shadow: ${({ showShadow }) =>
    showShadow
      ? "0px 0px 15px 0 rgba(65, 69, 88, 0.05), 0 5px 10px 0 rgba(0, 0, 0, 0.03)"
      : "none"};
`;

const AccordionItemContainer = styled(AccordionItem)`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

const CustomBadge = styled.div`
  top: -10px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  background: #1b8754;
  cursor: pointer;
`;

const CloseIconContainer = styled.div`
  top: -10px;
  right: -10px;
  cursor: pointer;
  background: #fff;
  padding: 2px 10px;
  position: absolute;
  border-radius: 50px;
`;

const TableWrap = styled.div`
  & > .table-responsive {
    overflow: visible;
  }
`;

const AgentViewActivityBtn = styled(Button)`
  font-size: 14px;
`;

const DealStageBadge = styled(Badge)`
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  border: ${({ stage }) =>
    `1px solid ${stage?.border ? stage.border : "#d9d9d9"}`};
  color: ${({ stage }) => (stage?.color ? stage.color : "rgba(0, 0, 0, 0.65)")};
  background-color: ${({ stage }) =>
    stage?.background ? stage.background : "#fafafa"} !important;
`;

const ArrayFieldsCard = styled(Card)`
  border: 0;
  padding: 10px;
  border-radius: 0;
  background: #fff;
`;

const CustomDroppableDiv = styled.div`
  background: var(--body-light-bg);
`;

export {
  Group,
  Legend,
  FieldSet,
  TableRow,
  NameLink,
  TableHead,
  ModalHead,
  AnswerRow,
  TableWrap,
  CustomCard,
  SubTextDiv,
  EllipsisDiv,
  PageHeading,
  CustomBadge,
  FCInputColor,
  HeaderColumn,
  BootstrapCard,
  CardContainer,
  FixedWidthDiv,
  BootstrapTable,
  DropdownToggle,
  FontSizeTwelve,
  DealStageBadge,
  FixedContainer,
  TableDetailCell,
  BorderlessInput,
  FunnelTypeBadge,
  ValiditySpinner,
  VerticalDivider,
  ArrayFieldsCard,
  BorderlessSelect,
  FunnelNameColumn,
  LastHeaderColumn,
  HorizontalDivider,
  FirstHeaderColumn,
  CloseIconContainer,
  FadedBackgroundDiv,
  FCInputColorButton,
  CustomDroppableDiv,
  TablePercentageCell,
  TableDetailNameCell,
  AgentViewActivityBtn,
  TableDetailSwitchCell,
  AccordionItemContainer,
  PerformanceCardContainer
};
