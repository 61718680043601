import { Col, Row } from "react-bootstrap";
import BenefitsGrid from "../../benefits-grid";

const BenefitsGridRow = ({ move, remove, fields = [] }) => {
  return fields.length ? (
    <Row className="mt-3">
      <Col xs={12}>
        <BenefitsGrid move={move} remove={remove} fields={fields} />
      </Col>
    </Row>
  ) : (
    <></>
  );
};
export default BenefitsGridRow;
