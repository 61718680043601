import { useRef } from "react";
import ReactQuill from "react-quill";
import { Modal, ModalTitle, Button } from "react-bootstrap";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

const Title = styled(ModalTitle)`
  text-transform: capitalize;
`;

const EditorModal = ({ title, onSave, onCancel, quillValue, className }) => {
  const { t } = useTranslation();
  const quillContent = useRef("");
  const handleQuillChange = (content) => {
    const quillString =
      content === "<p><br></p>" || content === "<p> </p>"
        ? content.replace(content, "")
        : content;
    quillContent.current = quillString;
  };

  return (
    <Modal size="lg" show={true} centered>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <ReactQuill
          className={className}
          value={quillValue}
          theme="snow"
          onChange={handleQuillChange}
          preserveWhitespace
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onCancel()} variant="secondary">
          {t("common.cancel")}
        </Button>
        <Button variant="success" onClick={() => onSave(quillContent.current)}>
          {t("common.save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default EditorModal;
