import { useFormContext } from "react-hook-form";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const BenefitsGridLabelAndButtonRow = ({ fields = [], addNew, gridLimit }) => {
  const { t } = useTranslation();
  const {
    formState: { errors }
  } = useFormContext();
  return (
    <Row>
      <Col xs={12}>
        <div className="d-flex align-items-center justify-content-between">
          <div
            className={`fw-bold ${
              errors?.data?.benefitsGrid ? "text-danger" : ""
            }`}
          >
            {`${t("preLandingPageOne.benefits.benefitsGridLabel")}
              ${errors?.data?.benefitsGrid ? "*" : ""}`}
          </div>
          <div className="ms-3">
            <Button
              className="w-100"
              onClick={addNew}
              disabled={fields.length === gridLimit}
            >
              {t("preLandingPageOne.benefits.addNewBenefitBtn")}
              {fields.length ? (
                <Badge bg="secondary" className="rounded-circle ms-2">
                  {fields.length}
                </Badge>
              ) : (
                ""
              )}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default BenefitsGridLabelAndButtonRow;
