import ReactQuill from "react-quill";
import { useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import TextField from "../../../reusable-form-fields/text-field";
import BenefitsGridRow from "./reusable-template-rows/benefits-grid-row";
import HeroImageAndContentRow from "./reusable-template-rows/hero-image-and-content-row";
import HeadlineTextAndColorRow from "./reusable-template-rows/headline-text-and-color-row";
import ColorPickerWithTextField from "../../../reusable-form-fields/color-picker-with-text-field";
import BenefitsGridLabelAndButtonRow from "./reusable-template-rows/benefits-grid-label-and-button-row";
import CallToActionButtonTextColorBackgroundRow from "./reusable-template-rows/cta-button-text-color-background-row";
import BenefitsGridHeadlineTextAndColorRow from "./reusable-template-rows/benefits-grid-headline-text-and-color-row";
import { useTranslation } from "react-i18next";

const LandingPageTempOne = () => {
  const { t } = useTranslation();
  const {
    watch,
    control,
    register,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: "data.benefitsGrid",
    rules: { maxLength: 6 }
  });
  const headerBackgroundColorPickerRef = useRef("");
  const heroHeadlineColorPickerRef = useRef("");
  const footerContent = watch("data.footerContent");

  const addNewBenefit = () => {
    append({
      id: `${fields.length + 1}`,
      position: fields.length,
      label: "",
      subText: "",
      icon: ""
    });
    if (errors?.data?.benefitsGrid) {
      clearErrors("data.benefitsGrid");
    }
  };

  // forcefully removing error
  useEffect(() => {
    if (footerContent) {
      clearErrors("data.footerContent");
    }
  }, [footerContent, clearErrors]);

  return (
    <div>
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageOne.headerBgColorLabel")}
            fieldName="data.headerBackgroundColor"
            fieldError={errors?.data?.headerBackgroundColor}
            fieldRef={headerBackgroundColorPickerRef}
            fieldVal={watch("data.headerBackgroundColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <HeadlineTextAndColorRow />
      <Row>
        <Col xs={12} md={6} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.heroHeadline"
            label={t("preLandingPageOne.heroHeadlineTextLabel")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageOne.heroHeadlineTextLabel"
            )}`}
            errors={errors?.data?.heroHeadline?.message}
          />
        </Col>
        <Col xs={12} md={6} className="mb-4">
          <ColorPickerWithTextField
            label={t("preLandingPageOne.heroHeadlineTextColorLabel")}
            fieldName="data.heroHeadlineColor"
            fieldError={errors?.data?.heroHeadlineColor}
            fieldRef={heroHeadlineColorPickerRef}
            fieldVal={watch("data.heroHeadlineColor")}
            isInGroup
            noSpacing
            hideErrMsg
          />
        </Col>
      </Row>
      <HeroImageAndContentRow transKey="preLandingPageOne" />
      <CallToActionButtonTextColorBackgroundRow />
      <BenefitsGridHeadlineTextAndColorRow />
      <BenefitsGridLabelAndButtonRow
        gridLimit={6}
        fields={fields}
        addNew={addNewBenefit}
      />
      <BenefitsGridRow move={move} remove={remove} fields={fields} />
      <Row className="mt-4">
        <Col xs={12} md={6}>
          <Form.Group controlId="footerContent">
            <Form.Label
              className={`mb-1 ${errors?.data?.footerContent && "text-danger"}`}
            >
              {`${t("preLandingPageOne.footerContent")} ${
                errors?.data?.footerContent ? "*" : ""
              }`}
            </Form.Label>
            <ReactQuill
              theme="snow"
              value={footerContent}
              onChange={(value) => {
                setValue("data.footerContent", value, { shouldDirty: true });
              }}
              preserveWhitespace
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};
export default LandingPageTempOne;
