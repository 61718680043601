import { useDialog } from "../../hooks/useDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { showError, showSuccess } from "../../services/toast-service";

const DeleteButton = ({
  onDelete,
  deleteId,
  variant,
  message,
  softDelete = false,
  roundedBtnSize = "sm",
  onDeleteSuccess = () => {},
  beforeDeleteConfirmation = () => {}
}) => {
  const { showConfirmationDialogBox, setDependentStates } = useDialog();
  const { t } = useTranslation();

  const handleDelete = async (id) => {
    if (softDelete) {
      onDelete(id);
    } else {
      try {
        const resp = await onDelete(id);
        if (resp) {
          onDeleteSuccess();
          showSuccess(t(message.success));
        }
      } catch (err) {
        showError(err.response.data.message || err.message);
        console.log(err);
      }
    }
  };

  const dialogResponseAction = async (action, values) => {
    if (action && values.hasOwnProperty.call(values, "id")) {
      handleDelete(values.id);
    }
  };

  const toggleDialog = () =>
    showConfirmationDialogBox({
      title: t("common.confirmDeletion"),
      dialogMessage: renderDialogMessage(),
      dialogType: "danger",
      responseAction: dialogResponseAction
    });

  const renderDialogMessage = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: t(message.confirmation)
      }}
    ></div>
  );
  const onDeleteTriggered = (id) => {
    setDependentStates({ id });
    toggleDialog();
  };
  const handleClick = () => {
    beforeDeleteConfirmation();
    onDeleteTriggered(deleteId);
  };

  return (
    <>
      {variant === "rounded" ? (
        <Button
          size={roundedBtnSize}
          className="rounded-circle"
          variant="outline-danger"
          onClick={handleClick}
        >
          <FontAwesomeIcon size="sm" icon={faTrash} />
        </Button>
      ) : (
        <Button variant="danger" onClick={handleClick}>
          {t("delete")}
        </Button>
      )}
    </>
  );
};

export default DeleteButton;
