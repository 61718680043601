import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { LANDING_PAGE_TEMPLATES } from "../../../../strings";
import TextField from "../../../../reusable-form-fields/text-field";
import ColorPickerWithTextField from "../../../../reusable-form-fields/color-picker-with-text-field";
import { useTranslation } from "react-i18next";

const BenefitsGridHeadlineTextAndColorRow = () => {
  const { t } = useTranslation();
  const { FUNNEL_LANDING_PAGE_TEMP_ONE } = LANDING_PAGE_TEMPLATES;
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const benefitTextColorPickerRef = useRef("");
  const checkTemplateType = (selectedId) =>
    watch("template")._id === selectedId;

  return (
    <Row>
      <Col
        xs={12}
        className="mb-4"
        md={checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_ONE) ? 4 : 6}
      >
        <TextField
          type="text"
          register={register}
          name="data.benefitsGridHeadline"
          label={t("preLandingPageOne.benefits.benefitsGridHeadline")}
          placeholder={`${t("common.enter")} ${t(
            "preLandingPageOne.benefits.benefitsGridHeadline"
          )}`}
          errors={errors?.data?.benefitsGridHeadline?.message}
        />
      </Col>
      {checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_ONE) && (
        <Col xs={12} md={4} className="mb-4">
          <TextField
            type="text"
            register={register}
            name="data.benefitsGridSubHeadline"
            label={t("preLandingPageOne.benefits.benefitsGridSubHeadline")}
            placeholder={`${t("common.enter")} ${t(
              "preLandingPageOne.benefits.benefitsGridSubHeadline"
            )}`}
            errors={errors?.data?.benefitsGridSubHeadline?.message}
          />
        </Col>
      )}
      <Col
        xs={12}
        className="mb-4"
        md={checkTemplateType(FUNNEL_LANDING_PAGE_TEMP_ONE) ? 4 : 6}
      >
        <ColorPickerWithTextField
          label={t("preLandingPageOne.benefits.benefitsGridTextColor")}
          fieldName="data.benefitsGridTextColor"
          fieldError={errors?.data?.benefitsGridTextColor}
          fieldRef={benefitTextColorPickerRef}
          fieldVal={watch("data.benefitsGridTextColor")}
          isInGroup
          noSpacing
          hideErrMsg
        />
      </Col>
    </Row>
  );
};
export default BenefitsGridHeadlineTextAndColorRow;
