import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  InputGroup,
  AccordionButton
} from "react-bootstrap";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { IconPicker } from "react-fa-icon-picker";
import { createRef, useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  FCInputColor,
  BootstrapCard,
  FCInputColorButton,
  CustomDroppableDiv,
  AccordionItemContainer
} from "../common-styling";
import {
  colorCodeRegex,
  openColorPicker,
  handleColorChange
} from "../../services/common-service";
import DeleteButton from "../delete-button";
import TextField from "../reusable-form-fields/text-field";
import { iconPickerStyles } from "../common-confgs/common-vars";
import { getItemStyle } from "../funnel-form/funnel-custom-accordion/drag-drop-helper";
import { useTranslation } from "react-i18next";

const AccordionHeaderButton = styled(AccordionButton)`
  background: #fff;
  box-shadow: none;
  border-radius: 0 !important;
  &:focus {
    box-shadow: none;
  }
`;

const USPAccordion = ({ move, remove, fields }) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    getValues,
    clearErrors,
    formState: { errors }
  } = useFormContext();
  const elRefs = useRef([]);
  const uspIcons = watch("design.uspIcons");

  // handling color picker dynamic reference for usp item color field
  if (elRefs.current.length !== 5) {
    elRefs.current = Array(5)
      .fill()
      .map((_, i) => elRefs.current[i]?.current || createRef());
  }

  const handleDragEnd = (dropResult) => {
    if (dropResult.destination) {
      if (dropResult.source.index !== dropResult.destination.index) {
        move(dropResult.source.index, dropResult.destination.index);
        getValues("design.uspIcons").forEach((_, idx) =>
          setValue(`design.uspIcons[${idx}].position`, idx)
        );
      }
    }
  };
  const clearUspFieldError = (value, index, field) => {
    if (value && errors?.design?.uspIcons[index]?.[field]) {
      clearErrors(`design.uspIcons[${index}][${field}]`);
    }
  };
  const handleUspColorTextChange = (val, uspIndex) => {
    clearUspFieldError(val, uspIndex, "color");
    elRefs.current[uspIndex].current.value = val;
  };
  const handleUspColorChange = (ev, uspIndex) => {
    clearUspFieldError(ev.target.value, uspIndex, "color");
    handleColorChange(ev, setValue);
  };
  const generateIconVal = (uspIconIndex) =>
    watch(`design.uspIcons[${uspIconIndex}].icon`)
      ? watch(`design.uspIcons[${uspIconIndex}].icon`)
      : "";
  const handleUspIconChange = (val, uspIndex) => {
    clearUspFieldError(val, uspIndex, "icon");
    setValue(`design.uspIcons[${uspIndex}].icon`, val);
  };
  const checkHasFieldError = ({ idx, property }) =>
    errors?.design?.uspIcons &&
    errors.design.uspIcons[idx] &&
    errors.design.uspIcons[idx][property];

  useEffect(() => {
    uspIcons.forEach((icon, iconIdx) => {
      if (
        colorCodeRegex.test(icon.color) &&
        uspIcons[iconIdx]?.color?.length === 7 &&
        elRefs.current[iconIdx].current
      ) {
        elRefs.current[iconIdx].current.value = icon.color;
      }
    });
  }, [uspIcons]);

  return (
    <Accordion className="mt-3">
      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId="droppable-usp">
          {(provided, snapshot) => (
            <CustomDroppableDiv
              className="p-3 pb-2"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {fields.map((item, index) => (
                <div key={item.id} className="d-flex align-items-start">
                  <AccordionItemContainer
                    eventKey={index}
                    className="flex-fill"
                  >
                    <Draggable
                      key={String(index)}
                      draggableId={String(index)}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <BootstrapCard key={index} $showShadow={false}>
                            <div>
                              <AccordionHeaderButton
                                className={
                                  errors?.design?.uspIcons &&
                                  errors.design.uspIcons[index]
                                    ? "text-danger"
                                    : ""
                                }
                              >
                                USP # {index + 1}
                              </AccordionHeaderButton>
                            </div>
                            <Accordion.Body className="bg-white">
                              <Card.Body className="px-0 pt-0 pb-2">
                                <Row className="align-items-center">
                                  <Col sm={12} md={4}>
                                    <TextField
                                      label={`${t("modern.label")}:`}
                                      placeholder={t(
                                        "modern.uspLabelPlaceholder"
                                      )}
                                      type="text"
                                      name={`design.uspIcons[${index}].label`}
                                      register={register}
                                      errors={
                                        errors?.design?.uspIcons
                                          ? errors.design.uspIcons[index]?.label
                                          : null
                                      }
                                      noSpacing
                                      boldLabel
                                      hideErrMsg
                                    />
                                  </Col>
                                  <Col sm={12} md={6}>
                                    <div>
                                      <div
                                        className={`form-label fw-bold ${
                                          checkHasFieldError({
                                            idx: index,
                                            property: "color"
                                          })
                                            ? "text-danger"
                                            : ""
                                        }`}
                                      >
                                        {`${t("modern.colorLabel")}:`}
                                      </div>
                                      <InputGroup className="align-items-center">
                                        <Form.Control
                                          type="text"
                                          placeholder="#000000"
                                          ref={elRefs.current[index]}
                                          {...(register && {
                                            ...register(
                                              `design.uspIcons[${index}].color`,
                                              {
                                                onChange: ({
                                                  target: { value }
                                                }) =>
                                                  handleUspColorTextChange(
                                                    value,
                                                    index
                                                  )
                                              }
                                            )
                                          })}
                                          isingroup="true"
                                          className={`${
                                            checkHasFieldError({
                                              idx: index,
                                              property: "color"
                                            })
                                              ? "border-danger"
                                              : ""
                                          }`}
                                        />
                                        <FCInputColor
                                          type="color"
                                          name={`design.uspIcons[${index}].color`}
                                          ref={elRefs.current[index]}
                                          onChange={(ev) =>
                                            handleUspColorChange(ev, index)
                                          }
                                          className={`${
                                            checkHasFieldError({
                                              idx: index,
                                              property: "color"
                                            })
                                              ? "border-danger"
                                              : ""
                                          }`}
                                        />
                                        <FCInputColorButton
                                          variant="outline-secondary"
                                          onClick={() =>
                                            openColorPicker(
                                              elRefs.current[index]
                                            )
                                          }
                                          className={`${
                                            checkHasFieldError({
                                              idx: index,
                                              property: "color"
                                            })
                                              ? "border-danger"
                                              : ""
                                          }`}
                                        >
                                          {t("common.selectColor")}
                                        </FCInputColorButton>
                                      </InputGroup>
                                    </div>
                                  </Col>
                                  <Col sm={12} md={2}>
                                    <div>
                                      <div
                                        className={`form-label fw-bold ${
                                          checkHasFieldError({
                                            idx: index,
                                            property: "icon"
                                          })
                                            ? "text-danger"
                                            : ""
                                        }`}
                                      >
                                        {`${t("modern.iconLabel")}:`}
                                      </div>
                                      <IconPicker
                                        value={generateIconVal(index)}
                                        onChange={(v) =>
                                          handleUspIconChange(v, index)
                                        }
                                        containerStyles={
                                          iconPickerStyles.containerStyles
                                        }
                                        searchInputStyles={
                                          iconPickerStyles.searchInputStyles
                                        }
                                        pickerIconStyles={
                                          iconPickerStyles.pickerIconStyles
                                        }
                                        buttonStyles={iconPickerStyles.buttonStyles(
                                          {
                                            hasError: checkHasFieldError({
                                              idx: index,
                                              property: "icon"
                                            })
                                          }
                                        )}
                                        buttonIconStyles={{
                                          ...iconPickerStyles.buttonIconStyles,
                                          color: watch(
                                            `design.uspIcons[${index}].color`
                                          )
                                            ? watch(
                                                `design.uspIcons[${index}].color`
                                              )
                                            : "#000"
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Accordion.Body>
                          </BootstrapCard>
                        </div>
                      )}
                    </Draggable>
                  </AccordionItemContainer>
                  <div className="ms-2 mt-2">
                    <DeleteButton
                      variant="rounded"
                      deleteId={index}
                      roundedBtnSize="md"
                      onDelete={() => remove(index)}
                      message={{
                        success: "modern.deleteUspSuccess",
                        confirmation: "modern.deleteUspMessage"
                      }}
                      softDelete
                    />
                  </div>
                </div>
              ))}
              {provided.placeholder}
            </CustomDroppableDiv>
          )}
        </Droppable>
      </DragDropContext>
    </Accordion>
  );
};
export default USPAccordion;
