import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import TextField from "../../../../reusable-form-fields/text-field";
import ColorPickerWithTextField from "../../../../reusable-form-fields/color-picker-with-text-field";
import { useTranslation } from "react-i18next";

const CallToActionButtonTextColorBackgroundRow = () => {
  const { t } = useTranslation();
  const {
    watch,
    register,
    formState: { errors }
  } = useFormContext();
  const ctaColorPickerRef = useRef("");
  const ctaBgColorPickerRef = useRef("");
  return (
    <Row>
      <Col xs={12} md={4}>
        <TextField
          type="text"
          register={register}
          name="data.ctaButtonText"
          label={t("preLandingPageOne.ctaButtonTextLabel")}
          placeholder={`${t("common.enter")} ${t(
            "preLandingPageOne.ctaButtonTextLabel"
          )}`}
          errors={errors?.data?.ctaButtonText?.message}
        />
      </Col>
      <Col xs={12} md={4} className="mb-4">
        <ColorPickerWithTextField
          label={t("preLandingPageOne.ctaButtonTextColorLabel")}
          fieldName="data.ctaButtonColor"
          fieldError={errors?.data?.ctaButtonColor}
          fieldRef={ctaColorPickerRef}
          fieldVal={watch("data.ctaButtonColor")}
          isInGroup
          noSpacing
          hideErrMsg
        />
      </Col>
      <Col xs={12} md={4} className="mb-4">
        <ColorPickerWithTextField
          label={t("preLandingPageOne.ctaButtonBackgroundColor")}
          fieldName="data.ctaButtonBackgroundColor"
          fieldError={errors?.data?.ctaButtonBackgroundColor}
          fieldRef={ctaBgColorPickerRef}
          fieldVal={watch("data.ctaButtonBackgroundColor")}
          isInGroup
          noSpacing
          hideErrMsg
        />
      </Col>
    </Row>
  );
};
export default CallToActionButtonTextColorBackgroundRow;
